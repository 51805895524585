jQuery(document).ready(function ($) {
	//Site LIst
	$(".select-category .select").click(function () {
		$(".select-category .popup").toggleClass("active")
	})

	$(document).click(function () {
		$(".select-category .popup").removeClass("active")
	})
	$(".select-category .select, .select-category .popup").click(function (e) {
		e.stopPropagation()
	})

	let queriedData = []
	let filteredData = []
	const html = (el) => {
		return `
			<a class="item" href="${el.url}"><figure style="background-image: url(${el.logo})"></figure></a>
		`
	}

	fetch(`https://swivs.themajor.com.au/wp-json/swivs/sites`)
		.then((response) => response.json())
		.then((data) => {
			queriedData = data
				.filter((el) => el.id !== "1")
				.sort((a, b) => (a.name > b.name ? 1 : -1))

			$(".lds-ring").hide()
			$(".site-categories, .site-list").css("display", "flex")

			queriedData.forEach((el) => {
				if (el.categories?.length <= 0) {
					return
				}
				$(".site-list").append(html(el))
			})
		})
		.catch((error) => {
			console.log(error)
		})

	$(".select-category .popup div").click(function () {
		var value = $(this).attr("data-value")
		$(".select-category .select").text(value)
		$(".select-category .popup").removeClass("active")

		if (value === "All") {
			$(".site-list").empty()
			queriedData.forEach((el) => {
				if (el.categories?.length <= 0) {
					return
				}
				$(".site-list").append(html(el))
			})
			return
		}

		filteredData = queriedData.filter((item) => {
			return item.categories?.includes(value)
		})

		$(".site-list").empty()
		filteredData.forEach((el) => {
			$(".site-list").append(html(el))
		})
	})
})
